import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontSize: 15,
    h1: {
      fontSize: 26,
      fontWeight: "bold",
    },
    h2: {
      fontSize: 22,
      fontWeight: "bold",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bolder",
    },
    body1: {
      fontSize: 16,
    },
  },
});

export default theme;
